@tailwind base;
@tailwind components;
@tailwind utilities;




body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
     /* background-image: url('./bg.jpg');  */
     color: black
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* 
button {
    cursor: pointer;
    display: block;
    width: 400px;
    padding: 20px;
    margin: auto;
    margin-bottom: 15px;
    color: red;
    border: none;
    border-radius: 10px;
} */

img {
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    margin-top: 50px;
    display: block;
    box-shadow: 0px 0px 10px black;
}
#form-meme{
    margin-left: 40px;
    margin-right: 40px ;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bolder;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    align-items: center;
    border-color: yellowgreen;
    border-radius: 10px;
    background-color: rgb(219, 209, 209);
    box-shadow: 0 4px 8px 0 rgba(255, 217, 4, 0.2), 0 6px 20px 0 rgba(230, 255, 3, 0.19);
}
/* input { */
    /* box-sizing: border-box; */
    /* display: block; */
    /* padding: 10px; */
    /* width: 350px; */
    /* margin: auto; */
    /* margin-bottom: 15px; */
/* } */

/* h1 { */
    /* text-align: center; */
    /* font-size: 50px; */
    /* text-shadow: 0px 0px 5px black; */
/* } */